import React, { useRef, useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-labels";
export default function AfflitiatesAttendedByGender({
  onChangeValue,
  chartsLoaded,
  dataForCharts
}) {
  const realRef = useRef();
  if (realRef.current) {
    console.log("f");
    const f = realRef.current.chartInstance.ctx;
    f.save();
    f.globalCompositeOperation = "destination-over";
    f.fillStyle = "#EEEEEE";
    f.fillRect(
      0,
      0,
      realRef.current.chartInstance.width,
      realRef.current.chartInstance.height
    );
    f.restore();
  }

  const [hideChart, setHiddeChart] = useState(false);
  const countFemale = dataForCharts.female;
  const countMale = dataForCharts.male;

  useEffect(() => {
    // when the chart load get us the base64image
    const img =
      realRef.current && realRef.current.chartInstance.toBase64Image();
    const v = countFemale === 0 && countMale === 0 ? "NO-DATA" : img;
    if (chartsLoaded.affliatesAttendedByGender !== v) {
      onChangeValue({
        key: "chartsLoaded",
        value: {
          ...chartsLoaded,
          affliatesAttendedByGender: v
        }
      });
    }
    // this is for hidde the chart
    if (chartsLoaded.affliatesAttendedByGender) {
      setHiddeChart(true);
    }
  }, [realRef, onChangeValue, chartsLoaded, countFemale, countMale]);

  const pie = {
    data: {
      labels: [
        `Cantidad de afilidados atendidos por Sexo F (${countFemale})`,
        `Cantidad de afilidados atendidos por Sexo M (${countMale})`
      ],
      datasets: [
        {
          data: [countFemale, countMale],

          backgroundColor: ["#FF6384", "#63FF84"]
        }
      ]
    },
    options: {
      legend: {
        // position: 'left',
        align: "start",
        labels: {
          fontSize: 40
        }
      },
      animation: {
        duration: 0
      },

      plugins: {
        labels: {
          fontSize: 40
        }
      }
    }
  };

  return (
    <div hidden={hideChart} className="border w-100 h-100" style={{}}>
      <Pie
        ref={realRef}
        data={pie.data}
        options={pie.options}
        plugins={ChartDataLabels}
      />
    </div>
  );
}
