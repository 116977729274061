import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import { useParams } from "react-router";
import ReactTable from "react-table-v6";
import { Label, Row, Card, CardBody } from "reactstrap";
import Col from "reactstrap/lib/Col";
import Input from "reactstrap/lib/Input";
import moment from "moment";

const query = gql`
  query getRegisteredPatientByPatient($where: WhereGetRegisteredPatient) {
    registeredPatientByPatient: getRegisteredPatientByPatient(where: $where) {
      appointmentDate
      appointmentId
      optimal {
        min
        max
      }
      metric
      result
      controlled
    }
    info: getInfo(where: $where) {
      activationDate
      affiliateName
      programName
    }
  }
`;

export default function Table({ state }) {
  const { programId, patientId, startDate, endDate } = useParams();
  // console.log()
  const { data } = useQuery(query, {
    variables: {
      where: {
        programId: parseInt(programId),
        patientId: parseInt(patientId),
        startDate,
        endDate
      }
    },
    fetchPolicy: true
  });

  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs={12}>
            <Label className="w-25 font-weight-bold">Métricas de Control</Label>
          </Col>
          <Col xs={3} className="d-flex m-2">
            <Label className="w-25 mt-2 ">Paciente</Label>
            <Input disabled value={data.info && data.info.affiliateName} />
          </Col>
          <Col xs={3} className="d-flex m-2">
            <Label className="w-25 m-2">PAM</Label>
            <Input disabled value={data.info && data.info.programName} />
          </Col>
          <Col xs={3} className="d-flex m-2">
            <Label className="w-50  mt-2">Fecha de inicio</Label>
            <Input disabled value={data.info && data.info.activationDate} />
          </Col>
          <Col xs={12} className="mt-2">
            <ReactTable
              data={data.registeredPatientByPatient}
              defaultPageSize={10}
              columns={[
                {
                  Header: "Ultima Cita",
                  className: "text-center",
                  accessor: "appointmentDate",
                  Cell: ({ original }) => (
                    <span>
                      {moment(original.appointmentDate).format(
                        "DD/MM/YYYY HH:mm A"
                      )}
                    </span>
                  )
                },
                {
                  Header: "Métrica ",
                  accessor: "metric"
                },
                {
                  Header: "Mínimo",
                  accessor: "optimal.min",
                  className: "text-center"
                },
                {
                  Header: "Máximo",
                  accessor: "optimal.max",
                  className: "text-center"
                },
                {
                  Header: "Resultado",
                  accessor: "result",
                  className: "text-center"
                },
                {
                  Header: "Estatus",
                  accessor: "controlled",
                  Cell: ({ original: { controlled } }) => (
                    <span>{controlled ? "Controlado" : "No Controlado"}</span>
                  )
                }
              ]}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
