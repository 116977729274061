import { lazy } from "react";
const Index = lazy(() => import("./pages"));

const routes = [
  {
    component: Index,
    exact: true,
    name: "Monitoreo de Citas Realizadas",
    // name: "KPI's Servicio al Cliente",
    path: "/dashboard/dating-monitoring"
  }
];

export default routes;
