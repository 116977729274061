import { lazy } from "react";

const MainPage = lazy(() => import("./page"));

const routes = [
  {
    path: "/center-management/daily-equipment-assignment",
    component: MainPage,
    exact: false,
    name: "Asignación Diaria de Equipos"
  }
];

export default routes;
