import React from "react";
import { Row, Col, Card, CardBody, TabContent } from "reactstrap";

import GeneralTab from "../management/tabs/GeneralTab";

export default function AnalysisMethodsManagement() {
  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" sm="12">
          <Card>
            <CardBody>
              <TabContent activeTab={1}>
                <GeneralTab />
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
