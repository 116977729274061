import React from "react";

const General = React.lazy(() => import("./pages/general"));
const TransitDetail = React.lazy(() => import("./pages/transit/detail"));
const DeliveredDetail = React.lazy(() => import("./pages/delivered/detail"));
const TransitReception = React.lazy(() => import("./pages/transit/reception"));
const New = React.lazy(() => import("./pages/general/management"));

const routes = [
  {
    exact: true,
    path: "/transference",
    name: "Transferencias",
    component: General
  },
  {
    exact: true,
    path: "/transference/transit-detail/:transferenceId",
    name: "Detalle de Transferencia Enviada",
    component: TransitDetail
  },
  {
    exact: true,
    path: "/transference/delivered-detail/:transferenceId",
    name: "Detalle de Transferencia Recibida",
    component: DeliveredDetail
  },
  {
    exact: true,
    path: "/transference/reception/:transferenceId",
    name: "Recepción de Transferencias",
    component: TransitReception
  },
  {
    exact: true,
    path: "/transference/new",
    name: "Captura de Transferencia",
    component: New
  }
];

export default routes;
