import React from "react";

const SuppliersrMainList = React.lazy(() => import("./pages/list"));
const SupplierMainManagement = React.lazy(() => import("./pages/management"));

const routes = [
  {
    exact: true,
    path: "/accounting/suppliers-list",
    name: "Suplidores",
    component: SuppliersrMainList
  },
  {
    exact: true,
    path: "/accounting/suppliers-list/new",
    name: "Nuevo Suplidor",
    component: SupplierMainManagement
  },
  {
    exact: true,
    path: "/accounting/suppliers-list/edit/:id",
    name: "Editar Suplidor",
    component: SupplierMainManagement
  }
];

export default routes;
