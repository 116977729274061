import { Modal, ModalBody, ModalHeader } from "reactstrap";

import React from "react";

export default ({ showModal, toggle, text }) => {
  return (
    <Modal isOpen={showModal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Detalle de la Interpretación</ModalHeader>
      <ModalBody>
        <span style={{ whiteSpace: "pre-line" }}>{text}</span>
      </ModalBody>
    </Modal>
  );
};
