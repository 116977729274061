import actionReducer, { initialState } from "./reducers";
import { useImmerReducer } from "use-immer";
import { useEffect } from "react";
import { useQuery } from "react-apollo-hooks";
import { GET_ANALYSIS_METHODS } from "./queries";

export default () => {
  const [state, dispatch] = useImmerReducer(actionReducer, {
    ...initialState
  });

  const getAnalysisMethods = useQuery(GET_ANALYSIS_METHODS, {
    variables: { where: {} },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    dispatch({
      type: "CHANGE_VALUE",
      value: getAnalysisMethods.loading,
      key: "loadingTable"
    });
  }, [dispatch, getAnalysisMethods.loading]);

  const refetch = query => {
    if (query) {
      getAnalysisMethods.refetch({ where: { name: query } });
    } else {
      getAnalysisMethods.refetch({ where: {} });
    }
  };

  useEffect(() => {
    if (getAnalysisMethods.data && !getAnalysisMethods.loading) {
      dispatch({
        type: "SET_ITEMS",
        value: getAnalysisMethods.data.methods
      });
    }
  }, [dispatch, getAnalysisMethods.data, getAnalysisMethods.loading]);
  return {
    state,

    actions: { refetch }
  };
};
