import React from "react";

const ProfileMainList = React.lazy(() => import("./pages/list"));
const ProfileMainManagement = React.lazy(() => import("./pages/management"));

const routes = [
  {
    exact: true,
    path: "/hmanagement/profile-list",
    name: "Perfiles",
    component: ProfileMainList
  },
  {
    exact: true,
    path: "/hmanagement/profile-list/new",
    name: "Nueva Perfil",
    component: ProfileMainManagement
  },
  {
    exact: true,
    path: "/hmanagement/profile-list/edit/:id",
    name: "Editar Perfil",
    component: ProfileMainManagement
  },
];

export default routes;
