import React, { useState, useEffect, useContext } from "react";

import * as Cookies from "es-cookie";
import { useImmerReducer } from "use-immer";
import createAuth0Client from "@auth0/auth0-spa-js";

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const initialState = {
  user: null,
  profile: null,
  loading: true,
  popupOpen: false,
  auth0Client: null,
  isAuthenticated: false
};

function actionReducer(draft, action) {
  switch (action.type) {
    case "CHANGE_FIELD":
      draft[action.field] = action.payload;
      return;
    case "CHANGE_FIELDS":
      const keys = Object.keys(action.values);
      for (let key of keys) draft[key] = action.values[key];
      return;
    default:
      return;
  }
}

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

export default function Auth0Provider({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) {
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useImmerReducer(actionReducer, initialState);

  useEffect(() => {
    window.onbeforeunload = () => {
      Cookies.remove("auth0.is.authenticated");
      Cookies.remove("_legacy_auth0.is.authenticated");
    };

    const initAuth0 = async () => {
      // return
      const auth0FromHook = await createAuth0Client(initOptions);
      dispatch({
        type: "CHANGE_FIELD",
        field: "auth0Client",
        payload: auth0FromHook
      });
      // console.log({ keys: Object.keys(auth0FromHook) });
      console.log({ auth0FromHook });
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      dispatch({
        type: "CHANGE_FIELD",
        field: "isAuthenticated",
        payload: isAuthenticated
      });

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();

        console.log({ auth0FromHook });
        dispatch({ type: "CHANGE_FIELD", field: "user", payload: user });
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await state.auth0Client.handleRedirectCallback();
    const user = await state.auth0Client.getUser();
    // console.log({ limpie: state });
    setLoading(false);
    dispatch({
      type: "CHANGE_FIELDS",
      values: {
        user: user,
        isAuthenticated: true
      }
    });
  };
  // console.log({
  //   state,
  // });
  return (
    <Auth0Context.Provider
      value={{
        loading,
        dispatch,
        user: state.user,
        profile: state.profile,
        handleRedirectCallback,
        isAuthenticated: state.isAuthenticated,
        logout: (...p) => state.auth0Client.logout(...p),
        getIdTokenClaims: (...p) => state.auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => state.auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => state.auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => state.auth0Client.getTokenWithPopup(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
}
