import React from "react";

const TherapeuticClassification = React.lazy(() => import("./pages/list"));
const TherapeuticClassificationManagement = React.lazy(() =>
  import("./pages/management")
);

const routes = [
  {
    path: "/medicines/therapeutic-classification",
    exact: true,
    name: "Clasificacion Terapeutica",
    component: TherapeuticClassification
  },
  {
    path:
      "/medicines/therapeutic-classification/edit/:therapeutic_classification_id",
    exact: true,
    name: "Editar Clasificacion Terapeutica",
    component: TherapeuticClassificationManagement
  },
  {
    path: "/medicines/therapeutic-classification/new",
    exact: true,
    name: "Crear Clasificacion Terapeutica",
    component: TherapeuticClassificationManagement
  }
];

export default routes;
