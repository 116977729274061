import React from "react";
import { Bar } from "react-chartjs-2";

export default function RegisteredPatients({ state }) {
  const t = state.registeredPatientsByPrograms;
  const actives = t.map(h => h.actives);
  const controlled = t.map(h => h.controlled);
  const unControlled = t.map(h => h.unControlled);

  const data = {
    labels: t.map(e => e.programName),

    datasets: [
      {
        backgroundColor: "#DE681B",
        data: actives,
        label: `Activos`
      },
      {
        backgroundColor: "#B78D72",
        data: controlled,
        label: `Controlados`
      },
      {
        backgroundColor: "#1A73D2",
        data: unControlled,
        label: `No Controlados`
      }
    ]
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              return value % 1 === 0 ? Math.trunc(value) : null;
            }
          }
        }
      ]
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar data={data} options={options} />
    </div>
  );
}
