import actionReducer, { initialState } from "./reducers";
import { useImmerReducer } from "use-immer";
import { useCallback, useEffect } from "react";
import { useMutation, useQuery } from "react-apollo-hooks";
import {
  GET_ANALYSIS_METHOD,
  CREATE_ANALYSIS_METHOD,
  UPDATE_ANALYSIS_METHOD,
  UPDATE_ANALYSIS_METHOD_PRODUCT
} from "./queries";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

const useActions = ({ id }) => {
  const isUpdating = id ? true : false;

  const [state, dispatch] = useImmerReducer(actionReducer, {
    ...initialState
  });

  const getAnalysisMethod = useQuery(GET_ANALYSIS_METHOD, {
    variables: { awhere: { id: parseInt(id) }, pwhere: { name: "" } },
    fetchPolicy: "network-only"
  });

  const createAnalysisMethodAction = useMutation(CREATE_ANALYSIS_METHOD);
  const updateAnalysisMethodAction = useMutation(UPDATE_ANALYSIS_METHOD);
  const updateAnalysisMethodProductAction = useMutation(
    UPDATE_ANALYSIS_METHOD_PRODUCT
  );

  const history = useHistory();
  const setValue = useCallback(
    ({ key, value }) => {
      dispatch({
        type: "CHANGE_VALUE",
        value,
        key
      });
    },
    [dispatch]
  );

  const toggleInitialValidation = value => {
    dispatch({
      type: "CHANGE_VALUE",
      key: "validations",
      value: { ...state.validations, initial: value }
    });
  };

  useEffect(() => {
    if (isUpdating) {
      getAnalysisMethod.refetch({ where: { id: Number(id) } });
    }
  }, [dispatch, getAnalysisMethod, id, isUpdating]);

  const onCreateAnalysisMethod = async ({ method }) => {
    const data = {
      name: method,
      analysisMethodProducts: state.productsCache,
      enabled: true
    };
    try {
      await createAnalysisMethodAction({
        variables: { data }
      });
      toast.success("Método de análisis creado correctamente");
      clearInputs();
      history.push("/administration/analysis-methods");
    } catch (error) {
      toast.error("Ocurrió un error al crear el método de análisis");
    }
  };
  const validateEqualProduct = value => {
    const coincidence = state.productsCache.some(e => {
      let count = 0;
      if (e.genderId === value.genderId) count++;
      if (e.productId === value.productId) count++;
      if (e.endAge === value.endAge) count++;
      if (e.startAge === value.startAge) count++;

      return count === 4;
    });
    return coincidence ? false : true;
  };

  const addNewProduct = () => {
    toggleInitialValidation(false);
    // if (!state.validations.gender) return;
    // if (!state.validations.product) return;
    // if (!state.validations.minMax) return;
    // if (!state.validations.endAge) return;
    // if (!state.validations.startAge) return;
    // if (!state.validations.unitMeasurement) return;
    // if (!state.validations.type) return;
    if (state.typeSelected === 5) {
      if (!state.validations.gender) return;
      if (!state.validations.product) return;
      // if (!state.validations.minMax) return;
      if (!state.validations.endAge) return;
      if (!state.validations.startAge) return;
      if (!state.validations.unitMeasurement) return;
      if (!state.validations.type) return;
    } else {
      if (!state.validations.gender) return;
      if (!state.validations.product) return;
      if (!state.validations.minMax) return;
      if (!state.validations.endAge) return;
      if (!state.validations.startAge) return;
      if (!state.validations.unitMeasurement) return;
      if (!state.validations.type) return;
    }

    const newValue = {
      genderId: state.selectedGenderId,
      interpretation: state.interpretation.trim(),
      productId: state.selectedProduct,
      minimum: state.min ? state.min.toString() : null,
      maximum: state.max ? state.max.toString() : null,
      endAge: state.endAge,
      startAge: state.startAge,
      unitMeasurement: state.unitMeasurement,
      typeId: state.typeSelected
    };

    const isValidateEqualProduct = validateEqualProduct(newValue);

    if (!isValidateEqualProduct) {
      toast.error(
        "Ya existe un producto con el mismo sexo, exámen de laboratorio y edad"
      );
      return;
    }

    if (isUpdating) newValue.id = -1;

    setValue({
      key: "productsCache",
      value: [...state.productsCache, newValue]
    });

    // ---------------------------------------------------
    dispatch({
      type: "VALIDATIONS_FALSE"
    });

    dispatch({
      type: "CLEAR_AMP_INPUTS"
    });
  };

  const onUpdateAnalysisMethod = async ({ method }) => {
    const toSend = {
      id: state.item.id,
      name: method,
      analysisMethodProducts: state.productsCache.map(amp => {
        return {
          id: amp.id,
          genderId: amp.genderId.toString(),
          interpretation: amp.interpretation,
          productId: Number(amp.productId),
          minimum: amp.minimum,
          maximum: amp.maximum,
          endAge: amp.endAge,
          startAge: amp.startAge,
          unitMeasurement: amp.unitMeasurement,
          typeId: amp.typeId
        };
      })
    };

    const n = toSend.analysisMethodProducts[0].genderId;
    console.log(n.toString());

    try {
      await updateAnalysisMethodAction({ variables: { data: toSend } });
      toast.success("Metodo de análisis actualizado correctamente");
      clearInputs();
      history.push("/administration/analysis-methods");
    } catch (error) {
      toast.error("Ocurrió un error al actualizar el método de análisis");
    }
  };

  const clearInputs = () => {
    dispatch({ type: "CLEAR_ALL_INPUTS" });
  };

  useEffect(() => {
    if (isUpdating) {
      if (state.item) {
        dispatch({
          type: "CHANGE_VALUE",
          key: "method",
          value: state.item.name
        });
        dispatch({
          type: "CHANGE_VALUE",
          key: "unitMeasurement",
          value: state.item.unitMeasurement
        });
        if (state.item.type) {
          dispatch({
            type: "CHANGE_VALUE",
            key: "typeSelected",
            value: state.item.type.id
          });
        }
        if (state.item.analysisMethodProducts) {
          dispatch({
            type: "CHANGE_VALUE",
            key: "productsCache",
            value: state.item.analysisMethodProducts.map(emp => ({
              id: emp.id,
              maximum: emp.maximum,
              minimum: emp.minimum,
              endAge: parseInt(emp.endAge),
              startAge: parseInt(emp.startAge),
              genderId: Number(emp.gender.id),
              interpretation: emp.interpretation,
              productId: Number(emp.product.id),
              unitMeasurement: emp.unitMeasurement,
              typeId: emp.typeId,
              enabled: emp.enabled,
              name: emp.product.name
            }))
          });
        }
      }
    }
  }, [dispatch, isUpdating, state.item]);

  const validateproduct = useCallback(
    param => {
      dispatch({
        type: "CHANGE_VALUE",
        key: "validations",
        value: {
          ...state.validations,
          product: param !== -1
        }
      });
    },
    [dispatch, state.validations]
  );

  const validateGeneroSelected = useCallback(
    param => {
      dispatch({
        type: "CHANGE_VALUE",
        key: "validations",
        value: {
          ...state.validations,
          gender: param !== -1
        }
      });
    },
    [dispatch, state.validations]
  );

  const onFillInputsWithSelectedAMP = data => {
    dispatch({
      type: "CHANGE_VALUE",
      key: "ampSelectedToUpdate",
      value: data.id
    });
    const {
      interpretatation,
      endAge,
      genderId,
      maximum,
      minimum,
      productId,
      startAge,
      typeId,
      unitMeasurement
    } = data;
    dispatch({
      type: "CHANGE_VALUES",
      keys: [
        "endAge",
        "selectedGenderId",
        "selectedProduct",
        "startAge",
        "endAge",
        "unitMeasurement",
        "interpretation",
        "max",
        "min",
        "typeSelected"
      ],
      values: [
        endAge,
        genderId,
        productId,
        startAge,
        endAge,
        unitMeasurement,
        interpretatation,
        maximum,
        minimum,
        typeId
      ]
    });
  };

  // function executeAllValidations() {
  //   validateGeneroSelected(state.selectedGenderId);
  //   validateMaxMin(state.max);
  //   validateMaxMin(state.min);
  //   validateStartAge(state.startAge);
  //   validateTypeSelected(state.typeSelected);
  //   validateUnitMeasurement(state.unitMeasurement);
  //   validateproduct(state.method);
  //   validateGeneroSelected(state.selectedGenderId);
  // }

  const onUpdateAnalysisMethodProduct = async () => {
    const payload = {
      id: state.ampSelectedToUpdate,
      genderId: state.selectedGenderId.toString(),
      interpretation: state.interpretation,
      productId: state.selectedProduct,
      minimum: state.min,
      maximum: state.max,
      startAge: state.startAge,
      endAge: state.endAge,
      unitMeasurement: state.unitMeasurement,
      typeId: state.typeSelected
    };
    try {
      await updateAnalysisMethodProductAction({
        variables: { data: payload }
      });
      await getAnalysisMethod.refetch();
      toast.success("Método de análisis creado correctamente");
      dispatch({
        type: "CHANGE_VALUE",
        key: "ampSelectedToUpdate",
        value: -1
      });
      dispatch({
        type: "CLEAR_AMP_INPUTS"
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const validateTypeSelected = useCallback(
    param => {
      dispatch({
        type: "CHANGE_VALUE",
        key: "validations",
        value: {
          ...state.validations,
          type: param !== -1
        }
      });
    },
    [dispatch, state.validations]
  );

  const validateStartAge = useCallback(
    param => {
      dispatch({
        type: "CHANGE_VALUE",
        key: "validations",
        value: {
          ...state.validations,
          startAge: param !== -1
        }
      });
    },
    [dispatch, state.validations]
  );
  const validateUnitMeasurement = useCallback(
    param => {
      dispatch({
        type: "CHANGE_VALUE",
        key: "validations",
        value: {
          ...state.validations,
          unitMeasurement: param !== ""
        }
      });
    },
    [dispatch, state.validations]
  );

  const validateEndAge = useCallback(
    param => {
      dispatch({
        type: "CHANGE_VALUE",
        key: "validations",
        value: {
          ...state.validations,
          endAge: param !== -1
        }
      });
    },
    [dispatch, state.validations]
  );

  const validateMaxMin = useCallback(
    param => {
      dispatch({
        type: "CHANGE_VALUE",
        key: "validations",
        value: {
          ...state.validations,
          minMax: param !== ""
        }
      });
    },
    [dispatch, state.validations]
  );
  useEffect(() => {
    if (!getAnalysisMethod.loading && getAnalysisMethod.data) {
      dispatch({
        type: "SET_ITEM",
        value: getAnalysisMethod.data.methods
      });

      dispatch({
        type: "CHANGE_VALUES",
        keys: ["genders", "products", "analysisMethodTypes"],
        values: [
          getAnalysisMethod.data.genders,
          // getAnalysisMethod.data.products,
          getAnalysisMethod.data.methods.products,
          getAnalysisMethod.data.types
        ]
      });
    }
  }, [dispatch, getAnalysisMethod.data, getAnalysisMethod.loading]);

  return {
    state,
    actions: {
      setValue,
      onCreateAnalysisMethod,
      onUpdateAnalysisMethod,
      addNewProduct,
      toggleInitialValidation,
      validateproduct,
      validateGeneroSelected,
      validateMaxMin,
      validateStartAge,
      validateEndAge,
      validateUnitMeasurement,
      validateTypeSelected,
      onUpdateAnalysisMethodProduct,
      onFillInputsWithSelectedAMP,
      onRefetchAnalysisMethodProducts: getAnalysisMethod.refetch
    }
  };
};

export default useActions;
