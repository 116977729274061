import { lazy } from "react";

const MainPage = lazy(() => import("./page"));

const routes = [
  {
    path: "/administration/medical-equipment-type",
    component: MainPage,
    exact: false,
    name: "Tipos de Equipos Medicos"
  }
];

export default routes;
