import React, { createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";

const defaultContext = {};

const actionReducer = (draft, action) => {
  switch (action.type) {
    case "SET_VALUE":
      draft[action.field] = action.payload;
      return;
    case "LOGOUT":
      draft = {};
      sessionStorage.removeItem("profileSelected");
      return;
    default:
      return;
  }
};

export const AppContext = createContext(defaultContext);
export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children, value }) => {
  console.log(value);
  const initialState = { ...defaultContext, ...value };
  const [state, dispatch] = useImmerReducer(actionReducer, initialState);
  console.log(state);

  window.onbeforeunload = function(e) {
    localStorage.removeItem("EventPlusService");
  };
  const UserDataString = JSON.stringify(state);
  localStorage.setItem("userData", UserDataString);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const WithContext = Component => props => {
  return (
    <AppContext.Consumer>
      {({ state, dispatch }) => {
        return <Component context={state} dispatch={dispatch} {...props} />;
      }}
    </AppContext.Consumer>
  );
};
