export const initialState = {
  item: {},
  genders: [],
  products: [],
  productsCache: [],
  selectedProduct: -1,
  selectedGenderId: -1,
  interpretation: "",
  max: "",
  min: "",
  method: "",
  typeSelected: -1,
  unitMeasurement: "",
  analysisMethodTypes: [],
  ampSelectedToUpdate: -1,
  validations: {
    initial: true,
    gender: false,
    product: false,
    minMax: false,
    endAge: false,
    startAge: false,
    unitMeasurement: false,
    type: false
  },
  endAge: -1,
  startAge: -1
};

export default function actionReducer(draft, action) {
  switch (action.type) {
    case "SET_ITEM":
      draft.item = action.value;
      return;
    case "CHANGE_VALUE":
      draft[action.key] = action.value;
      return;

    case "CHANGE_VALUES":
      action.keys.forEach((key, index) => {
        const value = action.values[index];
        draft[key] = value;
      });
      return;

    case "SET_PRODUCT_CACHE":
      draft.productsCache = action.value;
      return;

    case "CLEAR_ALL_INPUTS":
      draft.selectedProduct = -1;
      draft.selectedGenderId = -1;
      draft.interpretation = "";
      draft.max = "";
      draft.min = "";
      draft.method = "";
      draft.unitMeasurement = "";
      draft.typeSelected = -1;
      draft.productsCache = [];
      return;
    case "CLEAR_AMP_INPUTS":
      draft.selectedProduct = -1;
      draft.selectedGenderId = -1;
      draft.interpretation = "";
      draft.max = "";
      draft.min = "";
      draft.endAge = -1;
      draft.startAge = -1;
      draft.unitMeasurement = "";
      draft.typeSelected = -1;
      return;
    case "VALIDATIONS_FALSE":
      draft.validations = {
        gender: false,
        product: false,
        min: false,
        max: false,
        interpretation: false,
        startAge: false,
        endAge: false,
        initial: true,
        unitMeasurement: false,
        type: false
      };
      return;
    // case 'TOGGLE_IS_UPDATING_ANALYSIS_METHOD_PRODUCT':
    //   draft.ampSelectedToUpdate = !draft.ampSelectedToUpdate
    //   return
    default:
      return;
  }
}
