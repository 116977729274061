import React from "react";

const MainManagement = React.lazy(() => import("./pages/main"));
const Edit = React.lazy(() => import("./pages/edit"));
const routes = [
  {
    path: "/operations/allergies",
    name: "Alergias",
    exact: true,
    component: MainManagement
  },
  {
    path: "/operations/allergies/edit/:id",
    name: "Editar Alergia",
    exact: true,
    component: Edit
  },
  {
    path: "/operations/allergies/new",
    name: "Agregar Alergia",
    exact: true,
    component: Edit
  }
];

export default routes;
