import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { useMutation } from "react-apollo-hooks";
import { Button } from "reactstrap";

export default function StatusButton({ itemId, status, mutate, onCompleted }) {
  const action = useMutation(mutate);

  const onAction = async () => {
    try {
      await action({
        variables: {
          where: {
            id: itemId
          },
          data: {
            enabled: !status
          }
        }
      });

      onCompleted && onCompleted();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Button
      type="button"
      style={{ marginLeft: "5px" }}
      className={classnames(
        { "btn-red": !status, "btn-green": status },
        "card-header-action"
      )}
      onClick={onAction}
    >
      <i
        className={classnames("fa", { "fa-ban": !status, "fa-check": status })}
      />
      {status && <span>{" Activo"}</span>}
      {!status && <span>{" Inactivo"}</span>}
    </Button>
  );
}

StatusButton.propType = {
  itemId: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  mutate: PropTypes.any.isRequired,
  onCompleted: PropTypes.func
};
