import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { logoBase64 } from "./logo-base64";
import { get } from "lodash";
import f from "moment/locale/es";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function index({ data, state }) {
  console.log({ data });
  const resultProgramsPyP =
    data &&
    data.programs.map(r => {
      const program = r.name;
      const fe =
        state.chartsLoaded.affiliatesByControl.find(
          u => u.program === program
        ) || "";
      return [
        {
          pageBreak: index % 3 === 0 && index !== 0 ? "before" : "",
          text: `\n\nPrograma ${program}\n\n`,
          decoration: "underline",
          style: {
            color: "#4B98B6",
            bold: true,
            fontSize: 10
          }
        },
        {
          text: `Se atendieron en consulta un total de ${parseInt(
            get(r, "news", 0)
          ) + parseInt(get(r, "following", 0))} afiliados\n\n`
        },
        {
          layout: "lightHorizontalLines", // optional
          table: {
            body: [
              [
                { text: "Total de afiliados nuevos", bold: true },
                get(r, "news", 0)
              ],
              [
                { text: "Total de seguimiento", bold: true },
                get(r, "following", 0)
              ]
            ]
          }
        },
        "\n",
        {
          columns: [
            {
              table: {
                body: [
                  [
                    { text: "Régimen", bold: true },
                    { text: "Masculino", bold: true },
                    { text: "Femenino", bold: true }
                  ],
                  [
                    "Controlado",
                    r.controledcontributivemale,
                    r.controledcontributivefemale
                  ]
                  // ["Controlado - Pensionado/Jubilado", 0, 0],
                  // ["Controlado - Subsidiado", 0, 0],
                  // [
                  //   "No Controlado - Contributivo",
                  //   r.unControledcontributivemale,
                  //   r.unControledcontributivefemale,
                  // ],
                  // ["No Controlado - Pensionado/Jubilado", 0, 0],
                  // ["No Controlado - Subsidiado", 0, 0],
                ]
              }
            },

            {
              image: fe.chartImage,
              width: 150
            },

            { text: "", width: 170, height: 300 }
          ]
        }
        // f,
      ];
    });

  const pdf = pdfMake.createPdf({
    content: [
      {
        image: logoBase64,
        width: 200,
        margin: [20, 0]
      },
      {
        text: `
                    
                    Prestador: ${data.provider}
                    RNC: ${data.rnc}
                    Comprobante Fiscal: ${data.ncf}
                    Período:  ${moment(data.period)
                      .locale("es")
                      .format("MMMM")} ${moment(data.period)
          .locale("es")
          .format("YYYY")} 
                    ARS: ${data.ars}`,

        // margin: [20, 0],
        style: {
          bold: false,
          fontSize: 14
        }
      },
      {
        text: `\n\n\n\n\n\n\n\n\n\n\n\n\nReporte Mensual de Indicadores de Programas\nde Promoción y Prevención`,
        style: {
          color: "#418A35",
          alignment: "center",
          fontSize: 14,
          underlined: true
        }
      },
      {
        pageBreak: "before",
        text: `Introducción`,
        style: {
          bold: true,
          fontSize: 10
        }
      },
      "\nAnexamos los indicadores de salud correspondientes a la población afiliada a los programas de prevencion y promoción, así como la carga de enfermedad en grupos específicos de la misma.",
      "\n Nuestra institución realizó el seguimiento a los grupos de riesgo para los planes contratados según sigue\n\n",
      {
        ul: data.programs.map(p => p.name)
      },
      {
        text: "\n\nResultados del Mes",
        style: {
          color: "#4B98B6",
          bold: true,
          fontSize: 12
        }
      },
      `\nEn el mes de ${moment(data.period)
        .locale("es")
        .format("MMMM")} del ${moment(data.period)
        .locale("es")
        .format("YYYY")} se realizaron un total  ${
        data.totalServices
      } servicios, para un total de ${
        data.total
      } afiliados, que asistieron al centro, de los cuales ${
        data.female
      } pertenencen al sexo femenino y ${
        data.male
      } al sexo masculino, detalle según sigue:\n\n`,
      { image: state.chartsLoaded.affliatesAttendedByGender, width: 300 },
      // {
      //   text: "\n\n\nAfiliados Atendidos por Régimen\n\n\n",
      //   style: {
      //     color: "#4B98B6",
      //     bold: true,
      //     fontSize: 12,
      //   },
      // },
      // { width: 300, image: state.chartsLoaded.affiliantesAttendedByRegimen },
      // "\n\n",
      {
        // pageBreak: "before",
        text: "Afiliados Atendidos por Grupo Etario\n\n\n",
        style: {
          color: "#4B98B6",
          bold: true,
          fontSize: 12
        }
      },
      {
        // layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: [100, 90],
          body: [
            [
              { text: "Rango edad", bold: true, fontSize: 10 },
              { text: "Total afiliados", bold: true, fontSize: 10 }
            ],
            [
              { text: "1-17 años", fontSize: 10 },
              {
                text:
                  parseInt(data["a0_1"]) +
                  parseInt(data["a2_5"]) +
                  parseInt(data["a6_10"]) +
                  parseInt(data["a11_17"]),
                alignment: "right"
              } || 0
            ],
            [
              { text: "18-40 años", fontSize: 10 },
              { text: data["a18_40"] || 0, alignment: "right" } || 0
            ],
            [
              { text: "41-60 años", fontSize: 10 },
              { text: data["a41_60"] || 0, alignment: "right" }
            ],
            [
              { text: "61-80 años", fontSize: 10 },
              { text: data["a61_80"] || 0, alignment: "right" }
            ],
            [
              { text: "81-110 años", fontSize: 10 },
              { text: data["a81_110"] || 0, alignment: "right" }
            ],
            [
              { text: "Total", bold: true, fontSize: 10 },
              {
                text: data.total,
                alignment: "right"
              }
            ]
          ]
        }
      },
      {
        text: "\nTipo de Consultas\n\n",
        color: "#4B98B6",
        bold: true,
        fontSize: 10
      },
      { width: 200, image: state.chartsLoaded.typeQuerys },
      {
        text: "\n\n\n\n\n\nTipo de cobertura\n\n",
        style: {
          color: "#4B98B6",
          bold: true,
          fontSize: 10
        }
      },
      { width: 300, image: state.chartsLoaded.coverageTypes },
      {
        text: "\nReferimientos\n\n",
        style: {
          color: "#4B98B6",
          bold: true,
          fontSize: 10
        }
      },
      "No hay referimientos",
      "\n\n\n\n",
      {
        // pageBreak: "before",
        text: "Resultados de Programas de Promoción  y Prevención",
        style: {
          color: "#4B98B6",
          bold: true,
          fontSize: 10
        }
      },
      ...resultProgramsPyP,
      // next page
      {
        pageBreak: "before",
        text: "Ficha general del mes\n\n",
        style: {
          color: "#4B98B6",
          bold: true,
          fontSize: 10
        }
      },

      {
        table: {
          widths: [270, "auto", 150],
          headerRows: 1,

          body: [
            [
              {
                text: "Informe General de Servicios",
                colSpan: 3,
                alignment: "center",
                bold: true,
                fontSize: 10
              },
              {},
              {}
            ],
            // [
            //   {
            //     text: "Centro",
            //     style: "tableHeader",
            //     colSpan: 2,
            //     alignment: "center",
            //   },
            //   {},
            //   { text: data.provider, alignment: "center" },
            // ],
            [
              {
                text: "Régimen por consultas",
                alignment: "left"
              },

              [
                { text: "Contributivo", alignment: "center" },
                { text: "Pensionado", alignment: "center" }
              ],
              [
                {
                  text: data.contributive,
                  alignment: "center"
                },
                { text: 0, alignment: "center" }
              ]
            ],
            [
              {
                text: "Cantidad de afiliados atendidos por sexo",
                alignment: "left"
              },

              [
                { text: "F", alignment: "center" },
                { text: "M", alignment: "center" }
              ],
              [
                {
                  text: data.female,
                  alignment: "center"
                },
                {
                  text: data.male,
                  alignment: "center"
                }
              ]
            ],
            [
              {
                text: "Total consultas PyP primera vez",
                colSpan: 2,
                alignment: "left"
              },
              {},
              { text: data.new, alignment: "center" }
            ],
            [
              {
                text: "Total consultas PyP seguimiento",
                colSpan: 2,
                alignment: "left"
              },
              {},
              { text: data.following, alignment: "center" }
            ],
            [
              { text: "Total referimientos", colSpan: 2, alignment: "left" },
              {},
              { text: 0, alignment: "center" }
            ],
            [
              {
                text: "Total imágenes realizadas",
                colSpan: 2,
                alignment: "left"
              },
              {},
              { text: data.consultImages, alignment: "center" }
            ],
            [
              {
                text: "Total afiliados con laboratorio",
                colSpan: 2,
                alignment: "left"
              },
              {},
              { text: data.eventLaboratories, alignment: "center" }
            ],
            [
              {
                text: "Total analíticas realizadas con laboratorio",
                colSpan: 2,
                alignment: "left"
              },
              {},
              { text: data.consultLaboratories, alignment: "center" }
            ]
          ]
        }
      },
      // {
      //   text: "\n\nAfiliados Atendidos por Grupo Etario\n\n",
      //   style: {
      //     color: "#4B98B6",
      //     bold: true,
      //     fontSize: 10,
      //   },
      // },
      // {
      //   table: {
      //     headerRows: 1,
      //     body: [
      //       [
      //         {
      //           text: "Afiliados Atendidos por Grupo Etario",
      //           bold: true,
      //           colSpan: 9,
      //           alignment: "center",
      //           fontSize: 10,
      //         },
      //         {},
      //         {},
      //         {},
      //         {},
      //         {},
      //         {},
      //         {},
      //         {},
      //       ],
      //       [
      //         {
      //           text: "Afiliados atendidos por grupo etario",
      //           rowSpan: 1,
      //           alignment: "center",
      //           colSpan: 9,
      //         },
      //         {},
      //         {},
      //         {},
      //         {},
      //         {},
      //         {},
      //         {},
      //         {},
      //       ],
      //       [
      //         { text: "Rango Edad", alignment: "center" },
      //         { text: "0-1 año", alignment: "center" },
      //         { text: "2-5 años", alignment: "center" },
      //         { text: "6-10 años", alignment: "center" },
      //         { text: "11-17 años", alignment: "center" },
      //         { text: "18-40 año", alignment: "center" },
      //         { text: "41-60 años", alignment: "center" },
      //         { text: "61-80 años", alignment: "center" },
      //         { text: "81-110 años", alignment: "center" },
      //       ],
      //       [
      //         { text: "Cantidad consultas", alignment: "center" },
      //         {
      //           text: data["a0_1"],
      //           alignment: "center",
      //         },
      //         {
      //           text: data["a2_5"],
      //           alignment: "center",
      //         },
      //         {
      //           text: data["a6_10"],
      //           alignment: "center",
      //         },
      //         {
      //           text: data["a11_17"],
      //           alignment: "center",
      //         },
      //         {
      //           text: data["a18_40"],
      //           alignment: "center",
      //         },
      //         {
      //           text: data["a41_60"],
      //           alignment: "center",
      //         },
      //         {
      //           text: data["a61_80"],
      //           alignment: "center",
      //         },
      //         {
      //           text: data["a81_110"],
      //           alignment: "center",
      //         },
      //       ],
      //     ],
      //   },
      // },
      {
        text: "\n\nAfiliados Atendidos por Tipo de Cobertura\n\n",
        style: {
          color: "#4B98B6",
          bold: true,
          fontSize: 10
        }
      },
      {
        table: {
          headerRow: 2,
          widths: [100, 30, 100, 30, 100, 30],
          body: [
            [
              {
                text: "Tipo de cobertura",
                colSpan: 6,
                bold: true,
                alignment: "center",
                fontSize: 10
              },
              {},
              {},
              {},
              {},
              {}
            ],

            [
              {
                text: "Total de consultas",
                colSpan: 2,
                bold: true,
                alignment: "center"
              },
              {},
              {
                text: "Total de laboratorios",
                bold: true,
                alignment: "center",
                colSpan: 2
              },
              {},
              {
                text: "Total de imágenes",
                bold: true,
                alignment: "center",
                colSpan: 2
              },
              {}
            ],

            [
              { text: "Total de eventos" },
              {
                text: data.eventConsult,
                alignment: "right"
              },
              { text: "Total de eventos" },
              {
                text: data.eventLaboratories,
                alignment: "right"
              },
              { text: "Total de eventos" },
              {
                text: data.eventImages,
                alignment: "right"
              }
            ],

            [
              { text: "Total de consultas" },
              {
                text: data.totalAppointments,
                alignment: "right"
              },
              { text: "Total de pruebas" },
              {
                text: data.consultLaboratories,
                alignment: "right"
              },
              { text: "Total de imágenes" },
              {
                text: data.consultImages,
                alignment: "right"
              }
            ]
          ]
        }
      }
    ],
    footer: (currentPage, pageCount, pageSize) => {
      return [
        {
          columns: [
            "",
            {
              alignment: "right",
              text: currentPage
            }
          ],
          margin: [40, 0]
        }
      ];
    },
    defaultStyle: {
      fontSize: 8
    }
  });
  pdf.open();
}
