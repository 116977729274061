import React from "react";
import { Line } from "react-chartjs-2";
export default function AdherenceRateByProgram({ state }) {
  const programNames =
    state.adherenceRateByPrograms &&
    state.adherenceRateByPrograms.map(y => y.programName);
  const registered =
    state.adherenceRateByPrograms &&
    state.adherenceRateByPrograms.map(y => y.registered);
  const actives =
    state.adherenceRateByPrograms &&
    state.adherenceRateByPrograms.map(y =>
      Math.floor((y.actives / y.registered) * 100)
    );
  const lineChartData = {
    data: {
      labels: programNames,
      datasets: [
        {
          label: "Registrados",
          data: registered,
          fill: false,
          backgroundColor: "#1A73D2",
          yAxisID: "A",
          type: "bar"
        },
        {
          label: "Tasa",
          data: actives,
          fill: false,
          yAxisID: "B",
          backgroundColor: "#DF681B"
        }
      ]
    },
    options: {
      scales: {
        yAxes: [
          {
            id: "A",
            position: "left",
            ticks: {
              callback: value => {
                return value % 1 === 0 ? Math.floor(value) : null;
              },
              min: 0
            }
          },
          {
            id: "B",
            position: "right",
            order: 2,
            ticks: {
              max: 100,
              callback: value => {
                return `${value}.00%`;
              },
              min: 0
            }
          }
        ]
      }
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Line
        data={lineChartData.data}
        options={lineChartData.options}
        // height={30}
        // width={80}
      />
    </div>
  );
}
