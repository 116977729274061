export default {
  items: [
    {
      name: "Consultas",
      url: "/dashboard",
      icon: "fa fa-tachometer",
      children: [
        {
          name: "Efectividad de Citas Agendadas",
          url: "/dashboard/effectiveness-scheduleda-appointments",
          icon: "fa fa-bar-chart",
          permission: "dashboard_effectiveness-scheduleda-appointments"
        },
        {
          name: "Efectividad de Horas de Atención Médica",
          url: "/dashboard/effectiveness-medical-hours",
          icon: "fa fa-bar-chart",
          permission: "dashboard_effectiveness-medical-hours"
        },
        {
          name: "Efectividad de Tiempo de Cola",
          url: "/dashboard/effective-times-list",
          icon: "fa fa-bar-chart",
          permission: "dashboard_effective-times-list"
        },
        {
          name: "Esquema 40",
          url: "/dashboard/schema40",
          icon: "fa fa-archive",
          permission: "dashboard_schema40"
        },
        {
          name: "Manejo de Pacientes Cronicos",
          url: "/dashboard/chronic-patients",
          icon: "fa fa-bar-chart",
          permission: "dashboard_chronic-patients"
        },
        {
          name: "Medicamentos",
          icon: "fa fa-medkit",
          url: "/dashboard/medicines",
          permission: "medicine_medicines"
        },
        {
          // name: "Métricas Desempeño Médico",
          name: "KPI's Personal Médico",
          url: "/dashboard/performance-metrics-regular",
          icon: "fa fa-area-chart",
          permission: "dashboard_performace-metrics"
        },
        {
          // name: "Metricas Operativas",
          name: "KPI's Gestión de Centros",
          url: "/dashboard/operational-metrics",
          icon: "fa fa-area-chart",
          permission: "dashboard_operational-metrics"
        },
        {
          // name: "Monitoreo de Citas Programadas",
          name: "KPI's Servicio al Cliente",
          url: "/dashboard/inquiries-dating-monitoring",
          icon: "fa fa-bar-chart",
          permission: "dashboard_inquiries-dating-monitoring"
        },
        // {
        //   name: "Monitoreo de Citas Realizadas",
        //   url: "/dashboard/dating-monitoring",
        //   icon: "fa fa-calendar",
        //   permission: "dashboard_dating-monitoring"
        // },
        {
          name: "Niveles de inventario",
          url: "/dashboard/inventory",
          icon: "fa fa-archive",
          permission: "dashboard_inventory"
        },
        {
          name: "Pacientes Atendidos",
          url: "/dashboard/patients-attended",
          icon: "fa fa-pie-chart",
          permission: "dashboard_patients-attended"
        },
        {
          name: "Precios de Productos",
          url: "/dashboard/price-inquiry",
          icon: "fa fa-money",
          permission: "dashboard_price-inquiry"
        },
        {
          name: "Rentabilidad",
          url: "/dashboard/cost-effectiveness",
          icon: "fa fa-line-chart",
          permission: "dashboard_cost-effectiveness"
        }
      ]
    },
    {
      name: "Administracion",
      url: "/administration",
      icon: "fa fa-tasks",
      children: [
        {
          name: "Aseguradoras",
          url: "/services/ars",
          icon: "fa fa-heartbeat",
          permission: "administration_arses"
        },
        {
          name: "Aprobación de Compras - Proveedores",
          url: "/shopping/purchase-order-approval-provider",
          icon: "fa fa-thumbs-o-up",
          permission: "shopping_purchase-order-approval-provider"
        },
        {
          name: "Aprobación de Compras - Suplidores",
          url: "/shopping/purchase-order-approval-supplier",
          icon: "fa fa-thumbs-o-up",
          permission: "shopping_purchase-order-approval-supplier"
        },
        {
          name: "Centros",
          icon: "fa fa-hospital-o",
          url: "/operations/centers",
          permission: "operation_centers"
        },
        {
          name: "Metricas Operativas KPI",
          icon: "fa fa-area-chart",
          url: "/administration/customer-services-kpi",
          permission: "administration_customer_service_kpi"
        },
        {
          name: "Parametros Generales",
          url: "/accounting/parameters",
          icon: "fa fa-pencil-square-o",
          permission: "administration_general-parameters"
        },
        {
          name: "Productos",
          url: "/services/products",
          icon: "fa fa-archive",
          permission: "administration_products"
        },
        {
          name: "Productos Combinados",
          url: "/services/combinated",
          icon: "fa fa-th-large",
          permission: "administration_products-combinated"
        },
        {
          name: "Tiempos Optimos de Procesos de Cola",
          url: "/administration/effective-times-optimum",
          icon: "fa fa-area-chart",
          permission: "administration_effective-times-optimum"
        },
        {
          name: "Tipos de Equipos Medicos",
          url: "/administration/medical-equipment-type",
          icon: "fa fa-th",
          permission: "administration_medical-equipment-type"
        },
        {
          name: "Tipos de Productos",
          url: "/services/productTypes",
          icon: "fa fa-th",
          permission: "administration_products-types"
        }
      ]
    },
    {
      name: "Contabilidad",
      url: "/accounting",
      icon: "icon-calculator",
      children: [
        {
          url: "/accounting/ncf",
          icon: "fa fa-file-text",
          permission: "accounting_ncf",
          name: "Administracion de NCF"
        },
        {
          name: "Cargar Archivo de la DGII",
          icon: "fab fa-product-hunt",
          url: "/accounting/upload-dgii-file",
          permission: "accounting_upload-dgii-file"
        },
        {
          icon: "fa fa-pencil-square-o",
          name: "Conciliación de Cuadres",
          url: "/accounting/reconciliations",
          permission: "accounting_reconciliations"
        },
        {
          icon: "fa fa-codiepie",
          name: "Condiciones de Pago",
          url: "/accounting/payment-condition-list",
          permission: "accounting_payment-condition-list"
        },
        {
          name: "Empresas",
          icon: "fa fa-building-o",
          url: "/operations/companies",
          permission: "operation_companies"
        },
        {
          icon: "fa fa-book",
          name: "Entradas Contables",
          url: "/accounting/accounting-entry",
          permission: "accounting_entry"
        },
        {
          name: "Facturacion",
          icon: "fa fa-calculator",
          url: "/accounting/Billing",
          permission: "accounting_billing"
        },
        {
          name: "Liquidacion de Compras",
          url: "/shopping/purchase-order-liquidation",
          icon: "fa fa-external-link",
          permission: "shopping_purchase-order-liquidation"
        },
        {
          name: "Número de Autorización",
          url: "/accounting/autorization-num",
          icon: "fa fa-book",
          permission: "accounting_autorization_num"
        },
        {
          name: "Proveedores",
          icon: "fa fa-building-o",
          url: "/accounting/providers-list",
          permission: "accounting_providers-list"
        },
        {
          icon: "fa fa-money",
          name: "Registro de Cobro",
          url: "/accounting/register-payment",
          permission: "accounting_register-payment"
        },
        {
          name: "Suplidores",
          icon: "fab fa-product-hunt",
          url: "/accounting/suppliers-list",
          permission: "accounting_suppliers-list"
        },
        {
          icon: "fa fa-file-word-o",
          name: "Tipos de Comprobantes Fiscales",
          url: "/accounting/typetaxvouchers",
          permission: "accounting_typetaxvouchers"
        }
      ]
    },
    {
      name: "Gestión Médica",
      url: "",
      icon: "fa fa-stethoscope",
      children: [
        {
          name: "Alergias",
          icon: "fa fa-medkit",
          url: "/operations/allergies",
          permission: "operation_allergies"
        },
        {
          name: "Captura de Radiografía",
          url: "/medical-management/xray-capture",
          icon: "fa fa-user-md",
          permission: "patient-management_xray-capture"
        },
        {
          name: "Citas Médicas",
          icon: "fa fa-stethoscope",
          url: "/operations/appointment-internal",
          permission: "patient-management_appointment-internal"
        },
        {
          icon: "fa fa-wrench",
          name: "Equipos Medicos",
          url: "/medical-management/medical-equipment",
          permission: "medical-management_medical-equipment"
        },
        {
          icon: "fa fa-heartbeat",
          name: "Especialidades Médicas",
          url: "/services/specialities",
          permission: "operation_appointment-specialities"
        },
        {
          icon: "fa fa-list-alt",
          name: "Grupos de Parámetros Médicos",
          url: "/operations/medical-parameter-groups",
          permission: "operation_medical-parameter-groups"
        },
        {
          name: "Historia Clínica",
          url: "/operations/appointment-histories",
          icon: "fa fa-history",
          permission: "patient-management_appointment-histories"
        },
        {
          icon: "fa fa-list-alt",
          name: "Parámetros Médicos",
          url: "/operations/medical-parameters",
          permission: "operation_medical-parameters"
        },
        {
          icon: "fa fa-stethoscope",
          name: "Pre-Consulta",
          url: "/medical-management/pre-consultation",
          permission: "medical-management_appointment_pre_consultation"
        },
        {
          icon: "fa fa-list-alt",
          name: "Certificaciones de Preempleo",
          url: "/operations/certifications-pre-empleo",
          permission: "patient-management_certifications-pre-empleo"
        },
        {
          icon: "fa fa-calendar",
          name: "Programas de Atención Medica",
          url: "/operations/medical-attention-programs",
          permission: "operation_medical-attention-programs"
        },
        {
          icon: "fa fa-user-md",
          name: "Tipos de Citas Médicas",
          url: "/services/appointments",
          permission: "operation_appointment-types"
        },
        {
          icon: "fa fa-file-text",
          name: "Tipos de Licencias Médicas",
          url: "/operations/medical-licenses",
          permission: "operation_medical-licenses"
        }
      ]
    },
    {
      name: "Gestión Humana",
      url: "/hmanagement",
      icon: "fa fa-address-card",
      children: [
        {
          name: "Perfiles",
          url: "/hmanagement/profile-list",
          icon: "fa fa-users",
          permission: "hmanagement_profile"
        },
        {
          icon: "fa fa-user-md",
          name: "Personal Médico",
          url: "/hmanagement/mstaffs",
          permission: "hmanagement_mstaffs"
        },
        {
          name: "Usuarios",
          icon: "fa fa-user",
          url: "/hmanagement/users",
          permission: "hmanagement_users"
        }
      ]
    },
    {
      name: "Laboratorio",
      url: "",
      icon: "fa fa-flask",
      children: [
        {
          name: "Métodos de Análisis",
          url: "/administration/analysis-methods",
          icon: "fa fa-flask",
          permission: "administration_analysis-methods"
        },
        {
          name: "Paramétros de Pruebas Combinadas",
          url: "/administration/analysis-methods-hemograma",
          icon: "fa fa-flask",
          permission: "administration_analysis-methods-hemograma"
        },
        {
          name: "Pacientes en Proceso de Analíticas",
          url: "/laboratory/analitics-process",
          icon: "fa fa-flask",
          permission: "laboratory_analitics-process"
        },
        {
          icon: "fa fa-flask",
          name: "Toma de Muestra",
          url: "/operations/take-sample",
          permission: "laboratory_take-sample"
        }
      ]
    },
    {
      name: "Manejo de Pacientes",
      url: "/patient-management",
      icon: "fa fa-user-md",
      children: [
        {
          icon: "fa fa-indent",
          name: "Administración de Citas",
          url: "/operations/appointment-admin",
          permission: "patient-management_appointment-admin"
        },
        {
          icon: "fa fa-users",
          name: "Administración de Pacientes en PAM's",
          url: "/operations/patient-medical-attention-programs",
          permission: "patient-management_patient-medical-attention-programs"
        },
        {
          icon: "fa fa-book",
          name: "Agenda de Citas Médicas",
          url: "/operations/appointment-agenda",
          permission: "patient-management_appointment-agenda"
        },
        {
          icon: "fa fa-file-text",
          name: "Confirmación de Citas",
          url: "/patient-management/appointment-confirm",
          permission: "patient-management-appointment-confirm"
        },
        {
          name: "Frente de Linea",
          icon: "fa fa-stethoscope",
          url: "/operations/appointment-front-line",
          permission: "patient-management_appointment-front-line"
        },
        {
          name: "Motivos de Cancelación de Citas",
          icon: "fa fa-file-text",
          url: "/administration/reason-cancellation",
          permission: "administration_reason-cancellation"
        },
        {
          name: "Motivos de Suspensión de PAM",
          url: "/patient-management/reasons_for_pam_suspension",
          icon: "fa fa-file-text",
          permission: "patient-management-reasons_for_pam_suspension"
        },
        {
          name: "Pacientes",
          icon: "fa fa-users",
          url: "/operations/patients",
          permission: "operation_patients"
        },
        {
          name: "Pacientes en Espera",
          icon: "fa fa-users",
          url: "/operations/patient-await",
          permission: "patient-management_appointment-patient-await"
        },
        {
          name: "Punto de Venta",
          url: "/operations/pos",
          icon: "fa fa-shopping-bag",
          permission: "operation_pos"
        },
        {
          name: "Recuperacion de Contraseñas",
          url: "/patient-management/restart-password",
          icon: "fa fa-shopping-bag",
          permission: "patient-management_restart_password"
        },
        {
          name: "Reagendameinto de Citas",
          url: "/patient-management/appointment-rescheduling",
          icon: "fa fa-book",
          permission: "patient-management_appointment-rescheduling"
        }
      ]
    },
    {
      name: "Gestión de Centros",
      url: "/operations",
      icon: "fa fa-building-o",
      children: [
        {
          name: "Asignación Diaria de Equipos",
          url: "/center-management/daily-equipment-assignment",
          icon: "fa fa-wrench",
          permission: "center-management_daily-equipment-assignment"
        },
        {
          name: "Devoluciones de Ventas",
          url: "/sale-return/history",
          icon: "fa fa-shopping-bag",
          permission: "administration_sale-returns"
        },
        {
          name: "Devoluciones Mal Estado",
          url: "/disrepair-return",
          icon: "fa fa-external-link",
          permission: "shopping_bad-condition-return"
        },
        {
          name: "Devoluciones Regulares",
          url: "/regular-return",
          icon: "fa fa-external-link",
          permission: "shopping_regular-return"
        },
        {
          name: "Devoluciones Vencidos",
          url: "/due-return",
          icon: "fa fa-external-link",
          permission: "shopping_due-return"
        },
        {
          name: "Ordenes de Compras - Proveedores",
          url: "/shopping/purchase-order-relationship-provider",
          icon: "fa fa-american-sign-language-interpreting",
          permission: "shopping_purchase-order-relationship-provider"
        },
        {
          name: "Ordenes de Compras - Suplidores",
          url: "/shopping/purchase-order-relationship-supplier",
          icon: "fa fa-american-sign-language-interpreting",
          permission: "shopping_purchase-order-relationship-supplier"
        },
        {
          name: "Procesamiento de Cuadres",
          url: "/operations/settlements",
          icon: "fa fa-check-square-o",
          permission: "operation_settlements"
        },
        {
          name: "Recepcion de Compras - Proveedores",
          url: "/shopping/purchase-order-reception-provider",
          icon: "fa fa-thumb-tack",
          permission: "shopping_purchase-order-reception-provider"
        },

        {
          name: "Recepcion de Compras - Suplidores",
          url: "/shopping/purchase-order-reception-supplier",
          icon: "fa fa-thumb-tack",
          permission: "shopping_purchase-order-reception-supplier"
        },
        {
          name: "Recepcion de Conduces",
          url: "/shopping/purchase-conduce-relationship",
          icon: "fa fa-external-link",
          permission: "shopping_purchase-conduce-relationship"
        },
        {
          name: "Transferencias",
          url: "/transference",
          icon: "fa fa-external-link",
          permission: "shopping_transferences"
        }
      ]
    }
  ]
};
