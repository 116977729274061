import React from "react";
import ReactTable from "react-table-v6";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import ReactNumberFormat from "react-number-format";
import moment from "moment";

export default function Table({ state }) {
  // const { data } = useQuery(QUERY);
  const history = useHistory();

  return (
    <div>
      <ReactTable
        data={state.adherenceRateByPrograms}
        defaultPageSize={10}
        columns={[
          {
            Header: "Tipo de Programa",
            accessor: "programName"
          },
          {
            Header: "Registrados",
            classname: "text-right",
            Cell: ({ original }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-right"> {value} </div>
                )}
                displayType="text"
                value={original.registered}
                thousandSeparator={true}
              />
            )
          },
          {
            Header: "Activos",
            accessor: "actives",
            classname: "text-right",
            Cell: ({ original }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-right"> {value} </div>
                )}
                displayType="text"
                value={original.actives}
                thousandSeparator={true}
              />
            )
          },
          {
            Header: "Tasa",

            Cell: ({ original: { actives, registered } }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-center"> {value} </div>
                )}
                suffix="%"
                fixedDecimalScale={true}
                decimalScale={2}
                displayType="text"
                value={Math.floor((actives / registered) * 100)}
                thousandSeparator={true}
              />
            )
          },
          {
            Header: "Opciones",
            Cell: ({ original }) => (
              <Button
                onClick={() => {
                  history.push(
                    `/dashboard/performance-metrics-pyp/adherence-rate/program/${
                      original.programId
                    }/startDate/${moment(state.startDate).format(
                      "YYYY-MM-DD"
                    )}/endDate/${moment(state.endDate).format("YYYY-MM-DD")}`
                  );
                }}
                color="success"
              >
                {" "}
                Ver detalle
              </Button>
            )
          }
        ]}
      />
    </div>
  );
}
