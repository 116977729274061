import { lazy } from "react";

const MainPage = lazy(() => import("./page"));

const routes = [
  {
    path: "/dashboard/effectiveness-scheduleda-appointments",
    name: "Efectividad de Citas Agendadas",
    exact: true,
    component: MainPage
  }
];

export default routes;
