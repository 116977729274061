import PerformanceMetrics from "./pages/index";
import PerformanceMetricsRegisteredPatientsProgram from "./pages/pyp/registered-patients/Program";
import PerformanceMetricsRegisteredPatientsPatient from "./pages/pyp/registered-patients/Patient";
import PeformanceMetricsAdherencePatientsByProgram from "./pages/pyp/adherence-rate/Program";
import PerformanceMetricsAverageTimeAppointmentDetail from "./pages/regular/averageTime/Detail";
import PerformanceMetricsAverageTicketAppointmentDetail from "./pages/regular/averageTicket/Detail";
const routes = [
  /* PYP */
  {
    exact: true,
    path: "/dashboard/performance-metrics-pyp",
    name: "KPI's Personal Médico P&P",
    component: PerformanceMetrics
  },
  {
    exact: true,
    path:
      "/dashboard/performance-metrics-pyp/registered-patients/program/:programId/startDate/:startDate/endDate/:endDate",
    name: "Estatus de Pacientes Monitoreados",
    component: PerformanceMetricsRegisteredPatientsProgram
  },
  {
    exact: true,
    path:
      "/dashboard/performance-metrics-pyp/registered-patients/program/:programId/startDate/:startDate/endDate/:endDate/patient/:patientId",
    name: "Métricas de Control ",
    component: PerformanceMetricsRegisteredPatientsPatient
  },
  {
    exact: true,
    path:
      "/dashboard/performance-metrics-pyp/adherence-rate/program/:programId/startDate/:startDate/endDate/:endDate",
    name: "Estatus de Pacientes Adheridos",
    component: PeformanceMetricsAdherencePatientsByProgram
  },

  {
    exact: true,
    path:
      "/dashboard/performance-metrics-pyp/adherence-rate/program/:programId/startDate/:startDate/endDate/:endDate/patient/:patientId",
    name: "Métricas de Control ",
    component: PerformanceMetricsRegisteredPatientsPatient
  },
  /* regular*/
  {
    exact: true,
    path: "/dashboard/performance-metrics-regular",
    name: "KPI's Personal Médico Regular",
    component: PerformanceMetrics
  },
  {
    exact: true,
    path:
      "/dashboard/performance-metrics-regular/detail-average-time/appointment-type-id/:appointmentTypeId/startDate/:startDate/endDate/:endDate",
    name: "Detalle de Tiempo Promedio por Cita",
    component: PerformanceMetricsAverageTimeAppointmentDetail
  },
  {
    exact: true,
    path:
      "/dashboard/performance-metrics-regular/detail-average-ticket/appointment-type-id/:appointmentTypeId/startDate/:startDate/endDate/:endDate/appointmentName/:appointmentName",
    name: "Detalle de Ticket Promedio por Cita",
    component: PerformanceMetricsAverageTicketAppointmentDetail
  }
];

export default routes;
