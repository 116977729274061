import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import ReactTable from "react-table-v6";
import { Card, CardBody, Label } from "reactstrap";
import { useParams } from "react-router";
import ReactNumberFormat from "react-number-format";
import { Line } from "react-chartjs-2";
import Chart from "./ChartLine";

const QUERY = gql`
  query getAverageTicketByAppointmentByMedicalStaff(
    $where: WhereAverageAndTicket
  ) {
    averageTicketByAppointmentByMedicalStaff: getAverageTicketByAppointmentByMedicalStaff(
      where: $where
    ) {
      expectedTicket
      appointmentName
      averageTicket
      appointmentId
      medicalStaffId
      medicalStaffName
      centerId
      centerName
    }
    averageTicketByAppointmentByMedicalStaffByMonth: getAverageTicketByAppointmentByMedicalStaffByMonth(
      where: $where
    ) {
      name
      msId
      pId
      month
      expectedValue
      averageTicket
    }
  }
`;
export default function Table() {
  const {
    appointmentTypeId,
    startDate,
    endDate,
    appointmentName
  } = useParams();
  const { data } = useQuery(QUERY, {
    variables: {
      where: {
        appointmentTypeId: parseInt(appointmentTypeId),
        startDate,
        endDate
      }
    },
    fetchPolicy: true
  });
  const appointmentTypeName = appointmentName;

  const mappedData = (data?.averageTicketByAppointmentByMedicalStaff || [])
    .map(e => ({
      ...e,
      desviation:
        (parseInt(e.averageTicket) - parseInt(e.expectedTicket)) /
        parseInt(e.expectedTicket)
    }))
    .sort((a, b) => b.desviation - a.desviation);
  console.log({
    mappedData
  });
  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <Label className="font-weight-bold">{appointmentTypeName}</Label>
          <ReactTable
            data={mappedData}
            defaultPageSize={10}
            columns={[
              {
                Header: "Médico",
                accessor: "medicalStaffName"
              },
              {
                Header: "Centro",
                accessor: "centerName"
              },
              {
                Header: "Optimo",
                Cell: ({ original }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-right"> {value} </div>
                    )}
                    prefix="$"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    displayType="text"
                    value={original.expectedTicket}
                    thousandSeparator={true}
                  />
                )
              },
              {
                Header: "Promedio",
                Cell: ({ original }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-right"> {value} </div>
                    )}
                    prefix="$"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    displayType="text"
                    value={original.averageTicket}
                    thousandSeparator={true}
                  />
                )
              },
              {
                Header: "Desviación",
                // accessor: "status",
                Cell: ({ original: { desviation } }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-center">{value}</div>
                    )}
                    thousandSeparator={true}
                    displayType="text"
                    suffix="%"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    value={desviation * 100}
                  />
                )
              }
            ]}
          />
          <div className="mt-2">
            {" "}
            <Chart state={data} />{" "}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
