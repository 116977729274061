import { lazy } from "react";

const PurchaseOrderRelationList = lazy(() => import("./pages/list/index"));
const GeneralStep = lazy(() => import("./pages/management"));
const Offerts = lazy(() => import("./pages/management/offers/index"));
const AddOffert = lazy(() => import("./pages/management/offers/new/index"));
const OffertDetail = lazy(() =>
  import("./pages/management/offers/details/index")
);
const routes = [
  {
    exact: true,
    path: "/shopping/purchase-order-relationship-provider",
    name: "Ordenes de Compras",
    component: PurchaseOrderRelationList
  },
  {
    exact: true,
    path: "/shopping/purchase-order-relationship-provider/new",
    name: "Generacion de Ordenes de Compras",
    component: GeneralStep
  },
  //editar order de compra
  {
    exact: true,
    path: "/shopping/purchase-order-relationship-provider/edit/:id",
    name: "Generacion de Ordenes de Compras",
    component: GeneralStep
  },
  {
    exact: true,
    path: "/shopping/purchase-order-relationship-supplier/edit/:id",
    name: "Generacion de Ordenes de Compras",
    component: GeneralStep
  },
  {
    exact: true,
    path: "/shopping/purchase-order-relationship-provider/new/offerts/:id",
    name: "Ofertas",
    component: Offerts
  },
  {
    exact: true,
    path: "/shopping/purchase-order-relationship-supplier/new/offerts/:id",
    name: "Ofertas",
    component: Offerts
  },

  {
    exact: true,
    path: "/shopping/purchase-order-reception-provider/new/offerts/:id",
    name: "Ofertas",
    component: Offerts
  },
  {
    exact: true,
    path: "/shopping/purchase-order-reception-supplier/new/offerts/:id",
    name: "Ofertas",
    component: Offerts
  },

  {
    exact: true,
    path: "/shopping/purchase-order-relationship-supplier/new/offerts/:id/new",
    name: "Agregar Ofertas",
    component: AddOffert
  },
  {
    exact: true,
    path: "/shopping/purchase-order-relationship-provider/new/offerts/:id/new",
    name: "Agregar Ofertas",
    component: AddOffert
  },
  {
    exact: true,
    path:
      "/shopping/purchase-order-relationship-supplier/new/offerts/:id/details/:offertId",
    name: "Detalles",
    component: OffertDetail
  },
  {
    exact: true,
    path:
      "/shopping/purchase-order-relationship-provider/new/offerts/:id/details/:offertId",
    name: "Detalles",
    component: OffertDetail
  },
  {
    exact: true,
    path: "/shopping/purchase-order-relationship-supplier",
    name: "Ordenes de Compras",
    component: PurchaseOrderRelationList
  },
  {
    exact: true,
    path: "/shopping/purchase-order-relationship-supplier/new",
    name: "Generacion de Ordenes de Compras",
    component: GeneralStep
  }
];

export default routes;
