import React from "react";

const View = React.lazy(() => import("./pages"));

const routes = [
  {
    path: "/accounting/upload-dgii-file",
    component: View,
    exact: true,
    name: "Cargar Archivo de la DGII"
  }
];

export default routes;
