import React, { useState, useRef } from "react";
import { Bar } from "react-chartjs-2";
import { get } from "lodash";
import "chart.piecelabel.js";
export default function AffiliatesByControlled({
  onChangeValue,
  chartsLoaded,
  dataForCharts,
  id
}) {
  const realRef = useRef();
  const [hideChart, setHiddeChart] = useState(false);

  const femaleControlled = dataForCharts.controledcontributivefemale;
  const maleControlled = dataForCharts.controledcontributivemale;

  const femaleUnControlled = 0;
  const maleUnControlled = 0;

  const data = {
    labels: ["Controlados", "No Controlados"],
    datasets: [
      {
        backgroundColor: "#DE681B",
        data: [femaleControlled, femaleUnControlled],
        label: `Femenino`
      },
      {
        backgroundColor: "#B78D72",
        data: [maleControlled, maleUnControlled],
        label: `Masculino`
      }
    ]
  };

  const options = {
    legend: {
      // position: 'left',
      align: "start",
      labels: {
        fontSize: 40
      }
    },
    defaultFontSize: 0,

    scales: {
      yAxes: [
        {
          ticks: {
            callback: function(value) {
              return value % 1 === 0 ? value : null;
            },
            fontSize: 60
          }
        }
      ],

      xAxes: [
        {
          ticks: {
            // callback: function (value) {
            //   return value % 1 === 0 ? value : null;
            // },
            fontSize: 60
          }
        }
      ],

      plugins: {
        labels: {
          fontSize: 100
        }
      }
    },

    animation: {
      onComplete: e => {
        const img = e.chart.toBase64Image();

        if (
          chartsLoaded.affiliatesByControl &&
          !chartsLoaded.affiliatesByControl.some(k => k.chartImage === img)
        ) {
          const v =
            femaleControlled === 0 && maleControlled === 0 ? "NO-DATA" : img;
          const unImage = "data:,";
          if (img !== unImage) {
            onChangeValue({
              key: "chartsLoaded",
              value: {
                ...chartsLoaded,
                affiliatesByControl: [
                  ...chartsLoaded.affiliatesByControl,
                  {
                    program: dataForCharts.name,
                    chartImage: v,
                    id
                    // period: periodInput.label
                  }
                ]
              }
            });
          }
          setTimeout(() => {
            setHiddeChart(true);
          }, 100);
        }
      }
    },
    // maintainAspectRatio: false,

    title: {
      display: true,
      text: dataForCharts.name,
      fontSize: 50
    }
  };

  return (
    <div hidden={hideChart}>
      <Bar height={200} options={options} data={data} ref={realRef} />
    </div>
  );
}
