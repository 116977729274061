import React, { useState, useRef, useEffect } from "react";
import { HorizontalBar } from "react-chartjs-2";
export default function AffiliatesAttendedByRegimen({
  dataForCharts,
  onChangeValue,
  chartsLoaded
}) {
  const realRef = useRef();
  if (realRef.current) {
    const f = realRef.current.chartInstance.ctx;
    f.save();
    // f.globalCompositeOperation = 'destination-over';
    f.fillStyle = "#EEEEEE";
    f.fillRect(
      0,
      0,
      realRef.current.chartInstance.width,
      realRef.current.chartInstance.height
    );
    f.restore();
  }
  const [hideChart, setHiddeChart] = useState(false);
  const countPensioned = 0;
  const countContributive = dataForCharts.contributive;

  useEffect(() => {
    // when the chart load get us the base64image
    const img =
      realRef.current && realRef.current.chartInstance.toBase64Image();
    const f = countPensioned === 0 && countContributive === 0 ? "NO-DATA" : img;
    if (chartsLoaded.affiliantesAttendedByRegimen !== f) {
      onChangeValue({
        key: "chartsLoaded",
        value: {
          ...chartsLoaded,
          affiliantesAttendedByRegimen: f
        }
      });
    }
    // this is for hidde the chart
    if (chartsLoaded.affliatesAttendedByGender) {
      setHiddeChart(true);
    }
  }, [realRef, onChangeValue, chartsLoaded, countPensioned, countContributive]);

  const data = {
    datalabels: {
      align: e => {
        return e - 100;
      }
    },
    datasets: [
      {
        backgroundColor: "red",
        data: [countPensioned],
        label: `PENSIONADOS/JUBILADOS (${countPensioned})`
      },
      {
        backgroundColor: "blue",
        data: [countContributive],
        label: `CONTRIBUTIVO (${countContributive})`
      }
    ]
  };

  const options = {
    indexAxis: "x",
    scales: {
      xAxes: [
        {
          barPercentage: 0.3,
          ticks: {
            fontSize: 40
          }
        }
      ]
    },
    animation: {
      duration: 0
    },
    beforeDraw: () => {
      const f = realRef.current.chartInstance.ctx;
      f.save();
      // f.globalCompositeOperation = 'destination-over';
      f.fillStyle = "#EEEEEE";
      f.fillRect(
        0,
        0,
        realRef.current.chartInstance.width,
        realRef.current.chartInstance.height
      );
      f.restore();
    },
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      // position: 'left',
      align: "start",
      labels: {
        fontSize: 40
      }
    },

    plugins: {
      labels: {
        render: e => {
          return e.value;
        }
        // position: 'left',
      }
    }
  };

  return (
    <div hidden={hideChart}>
      <HorizontalBar height={200} options={options} data={data} ref={realRef} />
    </div>
  );
}
