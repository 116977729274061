import gql from "graphql-tag";

export const TOGGLE_ENABLED = gql`
  mutation toggleEnabled(
    $data: ToggleEnabledDataInput!
    $where: ToggleEnabledWhereInput!
  ) {
    toggleEnabled(data: $data, where: $where)
  }
`;

export const GET_ANALYSIS_METHODS = gql`
  query GetAnalysisMethods($where: AnalysisMethodWhereInput!) {
    methods: getAnalysisMethods(where: $where) {
      id
      enabled
      name
    }
  }
`;
