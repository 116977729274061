export const initialState = {
  items: [],
  loadingTable: true
};

export default function actionReducer(draft, action) {
  switch (action.type) {
    case "SET_ITEMS":
      draft.items = action.value;
      return;
    case "CHANGE_VALUE":
      draft[action.key] = action.value;
      return;
    default:
      return;
  }
}
