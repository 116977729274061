import React from "react";
import { Line } from "react-chartjs-2";

export default function Chart({ state }) {
  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const realData = state?.averageTicketByAppointmentByMedicalStaffByMonth || [];

  const datasets = realData
    .filter((v, i, a) => a.findIndex(t => t.name === v.name) === i)
    .map(e => {
      const data = realData.filter(f => f.name === e.name);

      const t = [];
      for (let i = 1; i <= 12; i++) {
        const f = data.find(d => d.month === i);
        t.push(f ? f.averageTicket : 0);
      }
      const color = getRandomColor();
      return {
        backgroundColor: color,
        data: t,
        fill: false,
        borderColor: color,
        borderWidth: 5,
        label: e.name
      };
    });

  console.log(datasets);
  return (
    <Line
      data={{
        labels: [
          "Mes 1",
          "Mes 2",
          "Mes 3",
          "Mes 4",
          "Mes 5",
          "Mes 6",
          "Mes 7",
          "Mes 8",
          "Mes 9",
          "Mes 10",
          "Mes 11",
          "Mes 12"
        ],
        datasets
      }}
      options={{
        title: {
          display: true,
          text: "Tendencia de Ticket Promedio por Cita",
          fontSize: 16
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function(value) {
                  return value % 1 === 0 ? `$${Math.trunc(value)}` : null;
                }
              }
            }
          ]
        }
      }}
    />
  );
}
