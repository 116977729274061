import React from "react";
import { useHistory } from "react-router";
import { Card, CardBody, Nav, NavItem, NavLink, TabPane } from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import TabContent from "reactstrap/lib/TabContent";
import Pyp from "./pyp";
import Regular from "./regular";
export default function Index() {
  const isPyp = window.location.pathname.includes("pyp");
  const activeTab = isPyp ? 1 : 0;
  const history = useHistory();

  const goToPyp = () => history.push("/dashboard/performance-metrics-pyp");
  const goToRegular = () =>
    history.push("/dashboard/performance-metrics-regular");

  return (
    <div className="animated fadeIn">
      <Card>
        <CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink active={activeTab === 0} onClick={goToRegular}>
                {"Citas Regulares"}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 1} onClick={goToPyp}>
                {"Citas P&P"}
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={0}>
              <Regular />
            </TabPane>
            <TabPane tabId={1}>
              <Pyp />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}
