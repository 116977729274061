import React from "react";

const Root = React.lazy(() => import("./pages/root"));
const Detail = React.lazy(() => import("./pages/root/Detail"));

const routes = [
  {
    name: "Aprobación de Devolución de Ventas",
    path: "/sale-return/aprove/:id?",
    exact: true,
    component: Root
  },
  {
    name: "Historial de Devolución de Ventas",
    path: "/sale-return/history",
    exact: true,
    component: Root
  },
  {
    name: "Detalle",
    path: "/sale-return/history/detail/:id",
    exact: true,
    component: Detail
  }
];

export default routes;
