import React from "react";
import ReactTable from "react-table-v6";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import ReactNumberFormat from "react-number-format";
import moment from "moment";

export default function Table({ state }) {
  const history = useHistory();

  const goToDetail = original =>
    history.push(
      `/dashboard/performance-metrics-regular/detail-average-ticket/appointment-type-id/${
        original.appointmentId
      }/startDate/${moment(state.startDate).format(
        "YYYY-MM-DD"
      )}/endDate/${moment(state.endDate).format(
        "YYYY-MM-DD"
      )}/appointmentName/${original.appointmentName}`
    );

  return (
    <div>
      <ReactTable
        data={state.averageTicketByAppointmentByTypeAppointment}
        defaultPageSize={10}
        columns={[
          {
            Header: "Tipo de Cita",
            Cell: ({ original }) => (
              <div className="">{original.appointmentName}</div>
            )
          },
          {
            Header: "Optimo",
            Cell: ({ original }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-right"> {value} </div>
                )}
                prefix="$"
                fixedDecimalScale={true}
                decimalScale={2}
                displayType="text"
                value={original.expectedValue}
                thousandSeparator={true}
              />
            )
          },
          {
            Header: "Promedio",
            Cell: ({ original }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-right"> {value} </div>
                )}
                prefix="$"
                fixedDecimalScale={true}
                decimalScale={2}
                displayType="text"
                value={original.averageValue}
                thousandSeparator={true}
              />
            )
          },
          {
            Header: "Desviación",
            Cell: ({ original: { averageValue, expectedValue } }) => (
              <ReactNumberFormat
                renderText={value => (
                  <div className="text-center"> {value} </div>
                )}
                suffix="%"
                fixedDecimalScale={true}
                decimalScale={2}
                displayType="text"
                value={((averageValue - expectedValue) / expectedValue) * 100}
                thousandSeparator={true}
              />
            )
          },
          {
            Header: "Opciones",
            Cell: ({ original }) => (
              <Button onClick={() => goToDetail(original)} color="success">
                {" "}
                Ver detalle
              </Button>
            )
          }
        ]}
      />
    </div>
  );
}
