import { lazy } from "react";
const List = lazy(() => import("./pages/list"));
const GeneralStep = lazy(() => import("./pages/management"));
const ServiceOrderList = lazy(() =>
  import("./pages/management/steps/ServiceOrderList")
);

const routes = [
  {
    exact: true,
    path: "/shopping/purchase-order-liquidation",
    name: "Liquidacion de Compras",
    component: List
  },
  {
    exact: true,
    path: "/shopping/purchase-order-liquidation/liquidation-supplier",
    name: "Liquidacion de Orden de Compra",
    component: GeneralStep
  },
  {
    exact: true,
    path: "/shopping/purchase-order-liquidation/liquidation-provider",
    name: "Liquidacion de Orden de Compra",
    component: GeneralStep
  },
  {
    exact: true,
    path:
      "/shopping/purchase-order-liquidation/liquidation-provider/service-order-list/:purchaseOrderId",
    name: "Detalle de Conduces",
    component: ServiceOrderList
  },
  {
    exact: true,
    path:
      "/shopping/purchase-order-liquidation/liquidation-supplier/service-order-list/:purchaseOrderId",
    name: "Detalle de Conduces",
    component: ServiceOrderList
  }
];

export default routes;
