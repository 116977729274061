import gql from "graphql-tag";
import React from "react";
import { useQuery } from "react-apollo-hooks";
import ReactTable from "react-table-v6";
import { Card, CardBody, Label } from "reactstrap";
import { useParams } from "react-router";
import ReactNumberFormat from "react-number-format";
import { Line } from "react-chartjs-2";
import Chart from "./ChartLine.js";

const QUERY = gql`
  query getAverageTimeByAppointmentByMedicalStaff(
    $where: WhereAverageAndTicket
  ) {
    averageTimeByAppointmentByMedicalStaff: getAverageTimeByAppointmentByMedicalStaff(
      where: $where
    ) {
      expectedDuration
      appointmentName
      averageDuration
      appointmentId
      medicalStaffId
      medicalStaffName
      centerId
      centerName
    }
    getAverageTimeByAppointmentByMedicalStaffByMonth: getAverageTimeByAppointmentByMedicalStaffByMonth(
      where: $where
    ) {
      name
      msId
      pId
      month
      expectedDuration
      averageDuration
    }
  }
`;
export default function Table() {
  const { appointmentTypeId, startDate, endDate } = useParams();
  const { data } = useQuery(QUERY, {
    variables: {
      where: {
        appointmentTypeId: parseInt(appointmentTypeId),
        startDate,
        endDate
      }
    },
    fetchPolicy: true
  });
  const appointmentTypeName =
    ((data?.averageTimeByAppointmentByMedicalStaff || [])[0] || {})
      ?.appointmentName || "Cargando...";

  const mappedData = (data?.averageTimeByAppointmentByMedicalStaff || [])
    .map(e => ({
      ...e,
      desviation: (e.averageDuration - e.expectedDuration) / e.expectedDuration
    }))
    .sort((a, b) => parseInt(b.desviation) - parseInt(a.desviation));
  return (
    <div className="animated fadeIn">
      <Card>
        <CardBody>
          <Label className="font-weight-bold">{appointmentTypeName}</Label>
          <ReactTable
            data={mappedData}
            defaultPageSize={10}
            columns={[
              {
                Header: "Médico",
                accessor: "medicalStaffName"
              },
              {
                Header: "Centro",
                accessor: "centerName"
              },
              {
                Header: "Optimo (Min)",
                className: "text-center",
                Cell: ({ original: { expectedDuration } }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-center">{value}</div>
                    )}
                    thousandSeparator={true}
                    displayType="text"
                    value={expectedDuration}
                  />
                )
              },
              {
                Header: "Promedio (Min)",
                className: "text-center",
                Cell: ({ original: { averageDuration } }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-center">{value}</div>
                    )}
                    thousandSeparator={true}
                    displayType="text"
                    value={averageDuration}
                  />
                )
              },
              {
                Header: "Desviación",
                // accessor: "status",
                Cell: ({ original: { desviation } }) => (
                  <ReactNumberFormat
                    renderText={value => (
                      <div className="text-center">{value}</div>
                    )}
                    thousandSeparator={true}
                    displayType="text"
                    suffix="%"
                    fixedDecimalScale={2}
                    decimalScale={true}
                    value={desviation}
                  />
                )
              }
            ]}
          />
          <div>
            <Chart state={data} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
