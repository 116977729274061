import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import * as FileSaver from "file-saver";
import moment from "moment";

const arsPamsReport = async ({ items, item }) => {
  // console.log({ items });
  // console.log({ item });
  const { ars, code } = item;

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Reporte de resultado de Analiticas");

  worksheet.state = "visible";

  const matchParameter = (data, id, actions, key) => {
    if (actions === "params") {
      const keys = Object.keys(data);

      let homologacion = [];
      for (let i = 0; i < keys.length; i++) {
        const element = keys[i];

        let filter = items.analisisMethods.filter(
          item => item.homologationId === element && item.examId === id
        );

        if (filter.length) {
          homologacion.push(filter[0]);
        }
      }

      return homologacion;
    } else if (actions === "results") {
      console.log({ result: data[key], key });

      return data[key];
    }
  };

  worksheet.columns = [
    { header: "Cedula", key: "Cedula" },
    { header: "NSS", key: "NSS" },
    { header: "Primer Nombre", key: "firstName" },
    { header: "Primer Apellido", key: "lastName" },
    { header: "Dirección", key: "Dirección" },
    { header: "Provincia", key: "Provincia" },
    { header: "Nombre Centro", key: "center" },
    { header: "Fecha Nacimiento", key: "birth" },
    { header: "Telefono", key: "Telefono" },
    { header: "Correo Electrónico", key: "email" },
    { header: "No. Autorizacion", key: "authorizationNo" },
    { header: "Fecha Factura", key: "date" },
    { header: "SIMON", key: "SIMON" },
    { header: "Producto - Complementario", key: "product" },
    { header: "Unidades", key: "unit" },
    { header: "Resultado", key: "results" },
    { header: "Valor Óptimo  (Binarios)", key: "optimo" },
    { header: "Valor Mínimo", key: "min" },
    { header: "Valor Máximo", key: "max" }
  ];

  items.analitics.map(e => {
    // console.log(e);
    return worksheet.addRow({
      Cedula: e.identificationCard,
      NSS: e.nss,
      firstName: e.firstName,
      lastName: e.lastName,
      Dirección: e.address,
      Provincia: e.provinces,
      center: e.center,
      birth: e.birth,
      Telefono: e.phone,
      email: e.email,
      authorizationNo: e.authorizationNo || "",
      date: moment(e.date).format("DD-MM-YYYY"),
      SIMON: e.codeSimon,
      product: e.name,
      unit: e.unit || "",
      results: e.results || "",
      optimo: e.min || "NA",
      min: e.min || "NA",
      max: e.max || "NA"
    });
  });

  items.hemograma.map(e => {
    for (let i = 0; i < matchParameter(e, 1654, "params", null).length; i++) {
      const element = matchParameter(e, 1654, "params", null)[i];
      worksheet.addRow({
        Cedula: e.identificationCard,
        NSS: e.nss,
        firstName: e.firstName,
        lastName: e.lastName,
        Dirección: e.address,
        Provincia: e.provinces,
        center: e.center,
        birth: e.birth,
        Telefono: e.phone,
        email: e.email,
        authorizationNo: e.authorizationNo || "",
        date: moment(e.date).format("DD-MM-YYYY"),
        SIMON: e.codeSimon,
        product: `${e.name} - ${element.parameter}`,
        unit: element.unit || "",
        results:
          matchParameter(e, 1654, "results", element.homologationId) || "",
        optimo: element.min || "NA",
        min: element.min || "NA",
        max: element.max || "NA"
      });
    }

    return worksheet;
  });

  items.orina.map(e => {
    for (let i = 0; i < matchParameter(e, 1238, "params", null).length; i++) {
      const element = matchParameter(e, 1238, "params", null)[i];
      worksheet.addRow({
        Cedula: e.identificationCard,
        NSS: e.nss,
        firstName: e.firstName,
        lastName: e.lastName,
        Dirección: e.address,
        Provincia: e.provinces,
        center: e.center,
        birth: e.birth,
        Telefono: e.phone,
        email: e.email,
        authorizationNo: e.authorizationNo || "",
        date: moment(e.date).format("DD-MM-YYYY"),
        SIMON: e.codeSimon,
        product: `${e.name} - ${element.parameter}`,
        unit: element.unit || "",
        results:
          matchParameter(e, 1238, "results", element.homologationId) || "",
        optimo: element.min || "NA",
        min: element.min || "NA",
        max: element.max || "NA"
      });
    }

    return worksheet;
  });

  items.coprologico.map(e => {
    for (let i = 0; i < matchParameter(e, 1173, "params", null).length; i++) {
      const element = matchParameter(e, 1173, "params", null)[i];
      worksheet.addRow({
        Cedula: e.identificationCard,
        NSS: e.nss,
        firstName: e.firstName,
        lastName: e.lastName,
        Dirección: e.address,
        Provincia: e.provinces,
        center: e.center,
        birth: e.birth,
        Telefono: e.phone,
        email: e.email,
        authorizationNo: e.authorizationNo || "",
        date: moment(e.date).format("DD-MM-YYYY"),
        SIMON: e.codeSimon,
        product: `${e.name} - ${element.parameter}`,
        unit: element.unit || "",
        results:
          matchParameter(e, 1173, "results", element.homologationId) || "",
        optimo: element.min || "NA",
        min: element.min || "NA",
        max: element.max || "NA"
      });
    }

    return worksheet;
  });

  items.CultivosData.map(e => {
    // console.log(e);
    // const microscopic = e?.microscopic[0] || []
    for (
      let i = 0;
      i < matchParameter(e?.microscopic[0], 0, "params", null).length;
      i++
    ) {
      const element = matchParameter(e?.microscopic[0], 0, "params", null)[i];
      worksheet.addRow({
        Cedula: e.identificationCard,
        NSS: e.nss,
        firstName: e.firstName,
        lastName: e.lastName,
        Dirección: e.address,
        Provincia: e.provinces,
        center: e.center,
        birth: e.birth,
        Telefono: e.phone,
        email: e.email,
        authorizationNo: e.authorizationNo || "",
        date: moment(e.date).format("DD-MM-YYYY"),
        SIMON: e.codeSimon,
        product: `${e.name} - ${element.parameter}`,
        unit: element.unit || "",
        results:
          matchParameter(
            e?.microscopic[0],
            0,
            "results",
            element.homologationId
          ) || "",
        optimo: element.min || "NA",
        min: element.min || "NA",
        max: element.max || "NA"
      });
    }

    return worksheet;
  });
  //CultivosData
  const blobType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelType = await workbook.xlsx.writeBuffer();
  const excel = new Blob([excelType], { type: blobType });
  FileSaver.saveAs(excel, `${ars}-${code || ""}.xlsx`);
};

export default arsPamsReport;
